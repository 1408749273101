
import { defineComponent, onMounted, ref } from "vue";
import { getGroupList, getUserList, setUserGroupApi } from "@/api/system";
import { UserGroupModel, GroupModel } from "@/api/systemModel";
import { ElMessage } from "element-plus";
import { deepClone } from "@/util/oauth";

export default defineComponent({
  name: "userManage",
  setup() {
    // 列表 //
    let loading = ref(false);
    let total = ref(0);
    let tableList = ref([]) as any;
    let cloneList = [] as any;
    const getList = async () => {
      loading.value = true;
      const res = await getUserList();
      loading.value = false;
      total.value = res.data.length;
      tableList.value = res.data.filter((x: any) => x != null);
      for (let item of tableList.value) {
        item.isShowPop = false;
      }
      cloneList = tableList.value;
    };
    // 搜索
    let searchform = ref({
      name: "",
    });
    const searchUser = () => {
      let users = deepClone(cloneList);
      for (let item of users) {
        item.realName = item.realName ?? "";
        item.userName = item.userName ?? "";
      }
      tableList.value = users.filter(
        (x: any) =>
          x.realName.indexOf(searchform.value.name) > -1 ||
          x.userName.indexOf(searchform.value.name) > -1
      );
    };

    // 获取分组
    let groupList = ref<Array<GroupModel>>();
    const getGroup = async () => {
      const res = await getGroupList();
      groupList.value = res.data as Array<GroupModel>;
    };
    // 设置分组
    let dialogForm = ref<UserGroupModel>({ userId: "", userProGroupArr: [] });
    const openPop = (row: any) => {
      row.isShowPop = true;
      dialogForm.value.userProGroupArr = [];
      for (let item of row.userGroup) {
        if (item.proGroupId != null) {
          dialogForm.value.userProGroupArr.push(item.proGroupId);
        }
      }
    };
    const setGroup = async (userId: string) => {
      dialogForm.value.userId = userId;
      if (dialogForm.value.userProGroupArr.length == 0) {
        ElMessage.warning("请至少选择一个分组");
        return;
      }
      const res = await setUserGroupApi(dialogForm.value);
      if (res.code == 200) {
        ElMessage.success("设置成功");
        getList();
      } else {
        ElMessage.error("设置失败");
      }
    };

    onMounted(() => {
      getGroup();
      getList();
    });
    return {
      searchform,
      searchUser,
      tableList,
      total,
      groupList,
      dialogForm,
      setGroup,
      openPop,
      loading,
    };
  },
});
